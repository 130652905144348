// Main color
$primary-color: #0849EF;
$secondary-color: #B120B7;
$third-color: #5138D8;

$blue-01: #0849EF;
$blue-02: #0031AF;
$blue-03: #050848;
$pink: #FF0B9A;
$white: #FFFFFF;
$green-success: #23B480;
$red-error: #C62937;
$grey-01: #10275B;
$grey-02: #223B6F;
$grey-03: #779CC5;
$grey-04: #95BADF;
$grey-05: rgba(183, 210, 232, 1);
$grey-06: #E8F3FD;
$grey-07: #FAFCFF;
$grey-color: #FAFCFF;

$payout-color: rgb(0, 207, 213);
$paid-color: rgb(88, 0, 232);
// Card Color
// $card-header-bg-color: #FAFCFF;

// // Table Component Colors
// $table-row-dark: #FAFCFF;
// $table-row-light: #FFFFFF;
// $table-header-border-bottom-color: #E7E7ED;
// $table-header-color: #050848;
// $table-row-bold-color: #223B6F;

// // Button
// $btn-default: #E8F3FD;
// $btn-default-hover: #B7D2E8;
// $btn-default-focus: #95BADF;
// $btn-dangerous: #FF0B9A;
// $btn-dangerous-hover: #DA0280;
// $btn-dangerous-focus: #A0005E;
// $btn-grey: #FAFAFA;
// $btn-grey-hover: #FAFAFA;
// $btn-grey-focus: #FAFAFA;
// $btn-grey-border: rgba(5, 8, 72, 0.05);
// $btn-gradient: linear-gradient(263.46deg, #E213A5 -31.4%, #3E3CDD 104.11%);
// $btn-gradient-hover: #FFFFFF;
// $btn-gradient-hover-text: linear-gradient(263.46deg, #E213A5 -31.4%, #3E3CDD 104.11%);
// $btn-gradient-focus: #FAFAFA;
// $btn-gradient-focus-border: rgba(5, 8, 72, 0.05);
// $btn-gradient-focus-text: #10275B;
// $btn-ghost: rgba(250, 252, 255, 0.2);
// $btn-ghost-hover: rgba(250, 252, 255, 0.4);
// $btn-ghost-focus: rgba(250, 252, 255, 0.6);

// // Input Color
// $input-focus-gradient: linear-gradient(45deg, rgba(8, 73, 239, 1), rgba(255, 11, 154, 1));
