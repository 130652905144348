@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "/src/assets/styles/variable.scss";
@import "/src/assets/styles/font.scss";
@import "/src/assets/styles/custom.scss";

body {
  font-family: 'Poppins', sans-serif;
}

.text-right {
  text-align: right;
}

.kyc-status-new {
  border-radius: 25px;
  background: rgba(250, 173, 20, 0.1);
  padding: 6px 16px;
  color: #faad14;
  border: none;
}

.kyc-status-rejected {
  border-radius: 25px;
  background: rgba(198, 41, 55, 0.1);
  padding: 6px 16px;
  color: $red-error;
  border: none;
}

.kyc-status-submitted {
  border-radius: 25px;
  background: rgba(35, 180, 128, 0.1);
  padding: 6px 16px;
  color: $green-success;
  border: none;
}

.kyc-status-activated {
  border-radius: 25px;
  background: rgba(8, 73, 239, 0.1);
  padding: 6px 16px;
  color: $blue-01;
  border: none;
}

.react-pdf__Page__canvas {
  width: 100% !important;
  height: auto !important;
  border: 1px solid gray;
}

.kyc-review-status-reject {
  border-radius: 25px;
  background: rgba(198, 41, 55, 0.1);
  padding: 6px 16px;
  color: $red-error;
  border: none;
}

.kyc-review-status-approve {
  border-radius: 25px;
  background: rgba(8, 73, 239, 0.1);
  padding: 6px 16px;
  color: $blue-01;
  border: none;
}

.status-active {
  border-radius: 25px;
  background: rgba(35, 180, 128, 0.1);
  padding: 6px 16px;
  color: $green-success;
  border: none;
}

.status-failed {
  border-radius: 25px;
  background: rgba(198, 41, 55, 0.1);
  padding: 6px 16px;
  color: $red-error;
  border: none;
}

.status-pending {
  border-radius: 25px;
  background: rgba(8, 73, 239, 0.1);
  padding: 6px 16px;
  color: $blue-01;
  border: none;
}

.status-payout {
  border-radius: 25px;
  background: rgba(0, 207, 213, 0.1);
  padding: 6px 16px;
  color: $payout-color;
  border: none;
}

.status-paid {
  border-radius: 25px;
  background: rgba(88, 0, 232, 0.1);
  padding: 6px 16px;
  color: $paid-color;
  border: none;
}

.flex-end {
  justify-content: end;
}

.flex-center {
  justify-content: center;
}

.cursor-pointer {
  cursor: pointer;
}

.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mx-4 {
  margin-left: 4px;
  margin-right: 4px;
}

.text-danger {
  color: $red-error;
}

.text-underline {
  text-decoration: underline;
}

.fw-bold {
  font-weight: bold;
}
