.col-filter {
  align-self: center;

  &.--group {
    column-gap: 10px;
    display: flex;
    height: 38px;

    button {
      height: 100%;
    }
  }
}
