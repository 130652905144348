.modal-preview {
  top: 10px;
}

.kyc-detail {
  &__btn-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  &__card {
    margin-bottom: 20px;
  }
}
