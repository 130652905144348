.ant-layout-sider-children {
  background: #050848;

  .ant-menu-dark {
    background: #050848;
  }
}

.ant-layout-sider-trigger {
  background: #050848;
  border-top: 1px solid rgba(232, 243, 253, .3)
}

.ant-modal-content {
  border-radius: 8px;
  overflow: hidden;
}

// Custom Spin
.ant-spin-nested-loading > div > .ant-spin {
  max-height: none;
}

.ant-spin-container.ant-spin-blur {
  opacity: 0.3;
}
