.preview-group {
  display: flex;
  margin-top: 12px;
  justify-content: space-between;
  &__action {
    display: flex;
    column-gap: 12px;
    align-items: center;
  }
}
