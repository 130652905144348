@import '/src/assets/styles/variable.scss';

.login-page {
  min-height: 100vh;
  background-color: #f0f2f5;
  text-align: center;
  position: relative;

  &__box {
    background-color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    top: 15%;

    &__container {
      width: 550px;
      height: 400px;
      padding: 30px 15px;
      display: flex;
      align-items: center;
    }

    &__content {
      &__title {
        font-family: 'Sequel 100 Wide 45', sans-serif;
        color: #0C1C85;
        font-size: 36px;
        line-height: 45px;
        letter-spacing: -1px;
        margin-bottom: 16px;
      }

      &__desc {
        font-family: 'Neurial Grotesk Light', sans-serif;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.15px;
        color: #050848;
        margin-bottom: 37px;
      }

      &__via-social {
        display: flex;
        justify-content: center;

        .btn {
          height: 62px;
          display: flex;
          align-items: center;
          padding-left: 11px;
          padding-right: 11px;
          border: 1px solid #10275B;
          font-family: 'Neurial Grotesk Light', sans-serif;
          font-size: 14px;
          color: #050848;

          span {
            margin-left: 14px;
          }

          &.btn-login-via-facebook,
          &.btn-login-via-google {

            &:hover,
            &:focus {
              background-color: #10275B;
              color: white;
            }
          }

          &.btn-login-via-google {
            padding-left: 17px;
            padding-right: 17px;
          }
        }
      }
    }
  }
}
