.header-container {
  z-index: 1000;

  .wrapper {
    height: 100%;
    display: flex;
    position: relative;
    align-items: center;
  }

  .ant-modal-body {
    padding: 10px;
  }

  .ant-modal-footer {
    border-top: none;
  }

  .btn-wrapper {
    position: absolute;
    right: 0;

    button {
      padding: 0;
    }
  }
}
