.mobile-access-warming-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  background: linear-gradient(141.98deg, #5138D8 6.99%, #B120B7 89.76%);

  .header {
    height: 80px;
    border-bottom: 1px solid rgba(232, 243, 253, 0.3);
    display: flex;
    padding: 0 15px;
    align-items: center;
    &__logo {
      height: 40px;
    }
  }

  .content {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    .ant-result-title {
      color: #FFF;
    }
  }

  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 40px;
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid rgba(232, 243, 253, 0.3);
  }
}
