@import "/src/assets/styles/variable.scss";

.auth-layout {
  min-height: 100vh;

  .trigger {
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
  }

  .site-layout .site-layout-background {
    background: #fff;
  }

  &__header {
    top: 0;
    padding: 0 24px;
    background: #FFF;
    width: 100%;
  }

  &__sider {
    &-content {
      left: 0;
      top: 0;
      bottom: 0;
      height: 100vh;
      position: sticky;
      overflow: auto;

      .logo {
        display: flex;
        justify-content: center;
        height: 48px;
        margin: 16px 16px 40px 16px;
        background: url('/assets/images/logo-white.svg') no-repeat;
        background-position: center;
      }

      &.ant-layout-sider-collapsed {
        .logo {
          background-image: url('/assets/images/logo-white-mini.svg');
        }
      }
    }
  }

  &__main-content {

    &-container {
      padding: 24px 24px 0 24px;
      min-height: calc(100vh - 136px);
    }
  }
}
