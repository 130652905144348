@font-face {
  font-family: "Neurial Grotesk";
  src: url("/assets/fonts/NeurialGrotesk-Regular.otf"),
    url("/assets/fonts/NeurialGrotesk-Regular.otf") format("truetype");
}

@font-face {
  font-family: "Neurial Grotesk Light", sans-serif;
  src: url("/assets/fonts/NeurialGrotesk-Light.otf"),
    url("/assets/fonts/NeurialGrotesk-Light.otf") format("truetype");
}

@font-face {
  font-family: "Neurial Grotesk Medium";
  src: url("/assets/fonts/NeurialGrotesk-Medium.otf"),
    url("/assets/fonts/NeurialGrotesk-Medium.otf") format("truetype");
}

@font-face {
  font-family: "Neurial Grotesk Bold";
  src: url("/assets/fonts/NeurialGrotesk-Bold.otf"),
    url("/assets/fonts/NeurialGrotesk-Bold.otf") format("truetype");
}

@font-face {
  font-family: "Sequel 100 Wide 45";
  src: url("/assets/fonts/OGJ Type Design - Sequel 100 Wide 45 Wide.otf"),
    url("/assets/fonts/OGJ Type Design - Sequel 100 Wide 45 Wide.otf") format("truetype");
}
